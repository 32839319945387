import React from "react";

const HomeSocials = () => {
  return (
    <div className="home__social">
      <a
        href="https://www.linkedin.com/in/robin-cassard/"
        className="home__social-icon"
        target="_blank"
        rel="noreferrer"
      >
        <i className="uil uil-linkedin"></i>
      </a>
      <a
        href="https://github.com/nexiath"
        className="home__social-icon"
        target="_blank"
        rel="noreferrer"
      >
        <i className="uil uil-github"></i>
      </a>
      <a
        href="https://twitter.com/robinmida"
        className="home__social-icon"
        target="_blank"
        rel="noreferrer"
      >
        <i className="uil uil-twitter"></i>
      </a>
      {/* <a
        href="https://discord.com/users/864127704231968789"
        className="home__social-icon"
        target="_blank"
        rel="noreferrer"
      >
        <i className="uil uil-discord"></i>
      </a> */}
      <a
        href="mailto:robin.cassard39@gmail.com"
        className="home__social-icon"
        target="_blank"
        rel="noreferrer"
      >
        <i className="uil uil-envelope-alt"></i>
      </a>
    </div>
  );
};

export default HomeSocials;
