import React, { useState, useContext } from "react";
import "./experience.css";
import { LanguageContext } from "../LanguageContext/LanguageContext";

const Experience = () => {
  const [toggleState, setToggleState] = useState(1);
  const { language } = useContext(LanguageContext);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <section className="qualification section" id="experience">
      <h2 className="section__title">{language === "fr" ? "Expérience" : "Experience"}</h2>
      <div className="qualification__container container">
        <div className="qualification__tabs">
          <div
            className={
              toggleState === 1
                ? "qualification__button button--flex qualification__active"
                : "qualification__button button--flex"
            }
            onClick={() => toggleTab(1)}
          >
            <i className="uil uil-graduation-cap qualification-icon"></i>{" "}
            {language === "fr" ? "Formation" : "Education"}
          </div>

          <div
            className={
              toggleState === 2
                ? "qualification__button button--flex qualification__active"
                : "qualification__button button--flex"
            }
            onClick={() => toggleTab(2)}
          >
            <i className="uil uil-briefcase-alt qualification-icon"></i>
            {language === "fr" ? "Expériences Professionnelles" : "Professional Experience"}
          </div>
        </div>

        <div className="qualification__sections">
          <div
            className={
              toggleState === 1
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">{language === "fr" ? "BUT Informatique" : "BUT Computer Science"}</h3>
                <span className="qualification__subtitle">
                  IUT Belfort-Montbéliard
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt"></i>2022-2024
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>

              <div>
                <h3 className="qualification__title">{language === "fr" ? "Économie et Gestion" : "Economics and Management"}</h3>
                <span className="qualification__subtitle">
                  UFR SJEPG, Besançon
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt"></i>2020-2022
                </div>
              </div>
            </div>

            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">{language === "fr" ? "Baccalauréat Scientifique" : "Scientific Baccalaureate"}</h3>
                <span className="qualification__subtitle">
                  Lycée Nodier, Dole
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt"></i>2020
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>
          </div>

          <div
            className={
              toggleState === 2
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">{language === "fr" ? "Stage Développeur Front-End" : "Front-End Developer Internship"}</h3>
                <span className="qualification__subtitle">
                  Elisath
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt"></i>2023-2024
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>

              <div>
                <h3 className="qualification__title">{language === "fr" ? "Projet Universitaire" : "University Project"}</h3>
                <span className="qualification__subtitle">
                  Vue.js, Node.js, MySQL
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt"></i>2023-2024
                </div>
              </div>
            </div>

            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">{language === "fr" ? "Projet Universitaire" : "University Project"}</h3>
                <span className="qualification__subtitle">
                  Java, Python, Flask
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt"></i>2022-2023
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">{language === "fr" ? "Hackathons & Open Source" : "Hackathons & Open Source"}</h3>
                <span className="qualification__subtitle">
                  {language === "fr" ? "Contributions et Compétitions" : "Contributions and Competitions"}
                </span>
                <div className="qualification__calendar">
                  <i className="uil uil-calendar-alt"></i>{language === "fr" ? "En cours" : "Ongoing"}
                </div>
              </div>

              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
