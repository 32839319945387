import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container container">
        <div className="footer__social">
          <a
            href="https://www.linkedin.com/in/robin-cassard/"
            className="footer__social-link"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bx bxl-linkedin-square"></i>
          </a>
          <a
            href="https://github.com/nexiath"
            className="footer__social-link"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bx bxl-github"></i>
          </a>
          <a
            href="https://twitter.com/robinmida"
            className="footer__social-link"
            target="_blank"
            rel="noreferrer"
          > 
            <i className="bx bxl-twitter"></i>
          </a>
          <a
            href="https://discord.com/users/yonkom"
            className="footer__social-link"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bx bxl-discord-alt"></i>
          </a>
          <a
            href="mailto:robin.cassard39@gmail.com"
            className="footer__social-link"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bx bxl-gmail"></i>
          </a>
        </div>

        <span className="footer__copyright">
          Robin Cassard | &copy; 2024
        </span>
      </div>
    </footer>
  );
};

export default Footer;
