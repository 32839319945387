import React from "react";
import Header from "./components/Header/Header";
import "./App.css";
import Home from "./components/Home/Home";
import About from "./components/about/About";
import Skills from "./components/skills/Skills";
import Experience from "./components/experience/Experience";
import Projects from "./components/Projects/Projects";
import Contact from "./components/Contact/Contact";
import Footer from "./components/footer/Footer";
import Portfolio from "./components/Portfolio/Portfolio";
import Scrollup from "./components/ScrollUp/Scrollup";
import { ThemeProvider } from "./components/ThemeContext/ThemeContext";
import { LanguageProvider } from "./components/LanguageContext/LanguageContext";

const App = () => {
  return (
    <LanguageProvider>
      <ThemeProvider>
        <Header />
        <main className="main">
          <Home />
          <About />
          <Skills />
          <Experience />
          <Portfolio />
          <Projects />
          <Contact />
        </main>
        <Footer />
        <Scrollup />
      </ThemeProvider>
    </LanguageProvider>
  );
};

export default App;
