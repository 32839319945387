import {useContext, React} from "react";
import { LanguageContext } from "../LanguageContext/LanguageContext";


const Info = () => {
  const { language } =
    useContext(LanguageContext);
  return (
    <div className="about__info grid">
      <a href="#experience">
        <div className="about__box">
          <i className="bx bx-award about__icon"></i>
          <h3 className="about__title">Experience</h3>
          <span className="about__subtitle"></span>
        </div>
      </a>
      
      <a href="#projects">
        <div className="about__box">
          <i className="bx bx-briefcase-alt about__icon"> </i>
          <h3 className="about__title">{language === "fr" ? "Projets" : "Projects"}</h3>
          <span className="about__subtitle"></span>
        </div>
      </a>
      <a href="#portfolio">
        <div className="about__box">
          <i className="bx bx-folder about__icon"> </i>
          <h3 className="about__title">{language === "fr" ? "Portfolio" : "Portfolio"}</h3>
          <span className="about__subtitle"></span>
        </div>
      </a>
    </div>
  );
};

export default Info;
