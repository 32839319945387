// LanguageContext.js
import React, { createContext, useState, useEffect } from 'react';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('fr');
  const [darkMode, setDarkMode] = useState(false);

  const toggleLanguage = (lang) => {
    setLanguage(lang);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [darkMode]);

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage, darkMode, toggleDarkMode }}>
      {children}
    </LanguageContext.Provider>
  );
};
