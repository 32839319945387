import React, { useContext } from 'react';
import { LanguageContext } from '../LanguageContext/LanguageContext';

const Backend = () => {
  const { language } = useContext(LanguageContext);

  const translations = {
    en: {
      title: "Back-End Development",
      skills: [
        { name: "Python", level: "Intermediate" },
        { name: "Java", level: "Intermediate" },
        { name: "MySQL", level: "Advanced" },
        { name: "Postman", level: "Advanced" },
        { name: "Node.js", level: "Advanced" },
        { name: "Docker", level: "Intermediate" } 
      ]
    },
    fr: {
      title: "Développement Back-End",
      skills: [
        { name: "Python", level: "Intermédiaire" },
        { name: "Java", level: "Intermédiaire" },
        { name: "MySQL", level: "Avancé" },
        { name: "Postman", level: "Avancé" },
        { name: "Node.js", level: "Avancé" },
        { name: "Docker", level: "Intermédiaire" } 
      ]
    }
  };

  const currentTranslations = translations[language];

  return (
    <div className="skills__content">
      <h3 className="skills__title">{currentTranslations.title}</h3>
      <div className="skills__box">
        <div className="skills__group">
          {currentTranslations.skills.slice(0, 3).map((skill, index) => (
            <div className="skills__data" key={index}>
              <i className={`bx bxl-${skill.name.toLowerCase()} skills-icon`}></i>
              <div>
                <h3 className="skills__name">{skill.name}</h3>
                <span className="skills__level">{skill.level}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="skills__group">
          {currentTranslations.skills.slice(3).map((skill, index) => (
            <div className="skills__data" key={index}>
              <i className={`bx bxl-${skill.name.toLowerCase()} skills-icon`}></i>
              <div>
                <h3 className="skills__name">{skill.name}</h3>
                <span className="skills__level">{skill.level}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Backend;
