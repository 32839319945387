import React, { useState, useContext } from 'react';
import { projectsData } from './Data';
import ProjectDetailsPopup from './ProjectDetailsPopup';
import { LanguageContext } from '../LanguageContext/LanguageContext';
import './Works.css';

const Works = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const { language } = useContext(LanguageContext);

  const handleProjectClick = (project) => {
    setSelectedProject(project);
  };

  const closePopup = () => {
    setSelectedProject(null);
  };

  return (
    <div className="works__container container grid">
      {projectsData.map((project) => (
        <div key={project.id} className="works__card" onClick={() => handleProjectClick(project)}>
          <img src={project.image} alt={project.title} className="works__img" />
          <h3 className="works__title">{project.title}</h3>
        </div>
      ))}

      <ProjectDetailsPopup project={selectedProject} onClose={closePopup} language={language} />
    </div>
  );
};

export default Works;
