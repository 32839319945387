import React from 'react';
import './ProjectDetailsPopup.css';
import parse from 'html-react-parser';

const ProjectDetailsPopup = ({ project, onClose, language }) => {
  if (!project) return null;

  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>X</button>
        <img src={project.image} alt={project.title} className="popup-image" />
        <h2>{project.title}</h2>
        <div>
          {language === 'fr' ? parse(project.description_fr) : parse(project.description_en)}
        </div>
        <div className="popup-links">
          <a href={project.demo} target="_blank" rel="noopener noreferrer">
            {language === 'fr' ? 'Démo' : 'Demo'}
          </a>
          <a href={project.code} target="_blank" rel="noopener noreferrer">
            {language === 'fr' ? 'Code' : 'Code'}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailsPopup;
