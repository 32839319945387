import React, { useContext } from "react";
import "./projects.css";
import Works from "./Works";
import { LanguageContext } from "../LanguageContext/LanguageContext";

const Projects = () => {
  const { language } = useContext(LanguageContext);

  return (
    <section className="projects section" id="projects">
      <h2 className="section__title">
        {language === 'fr' ? 'Projets' : 'Projects'}
      </h2>
      <Works />
    </section>
  );
};

export default Projects;
