import React, { useState, useContext } from "react";
import "./portfolio.css";
import { LanguageContext } from "../LanguageContext/LanguageContext";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Collapse, Modal, Button } from "antd";
import ModalImage from "react-modal-image";

const { Panel } = Collapse;

const acDescriptions = {
  en: {
    "Competency 1: Application Development with Nuxt 3": "Competence 1: Application Development\n\nDevelop a solution for a client by respecting their needs, applying algorithmic principles, ensuring code quality and documentation, and choosing appropriate technical resources.\n\nProfessional Situations:\n\n- Develop an application\n- Evolve an application\n- Maintain an application in operational conditions\n\nDevelop simple applications:\n\n- AC 1: Implement simple designs\n- AC 2: Develop simple designs\n- AC 3: Conduct tests and evaluate their results against specifications\n- AC 4: Develop user interfaces\n\nComplete application development:\n\n- AC 1: Develop and implement functional and non-functional specifications\n- AC 2: Apply accessibility and ergonomic principles\n- AC 3: Adopt good design and programming practices\n- AC 4: Verify and validate application quality through tests\n\nAdapt applications to various platforms (embedded, web, mobile, IoT…):\n\n- AC 1: Choose and implement appropriate architectures\n- AC 2: Evolve an existing application\n- AC 3: Integrate solutions in a production environment",
    "Competency 2: Application Performance Optimization": "Competence 2: Optimize Applications\n\nPropose optimized applications according to specific criteria (execution time, precision, resource consumption…).\n\nFormalize and model complex situations\n\n- Identify usual algorithms and data structures\n- Use reasoning schemes\n- Justify choices and validate results\n\nProfessional Situations:\n\n- Improve program performance in constrained contexts\n- Limit environmental impact of an application\n- Implement adapted and innovative applications\n\nUnderstand and build algorithms:\n\n- AC 1: Analyze a problem methodically\n- AC 2: Compare algorithms for classic problems\n- AC 3: Formalize and implement mathematical tools for computing\n\nSelect adequate algorithms for a given problem:\n\n- AC 1: Choose complex data structures adapted to the problem\n- AC 2: Use appropriate algorithmic techniques for complex problems\n- AC 3: Understand data and code security issues\n- AC 4: Evaluate environmental and societal impact of proposed solutions\n\nAnalyze and optimize applications:\n\n- AC 1: Anticipate various metric results (execution time, memory usage…)\n- AC 2: Profile, analyze and justify existing code behavior\n- AC 3: Choose and use dedicated libraries and methods for the application domain"
  },
  fr: {
    "Compétence 1 : Développement d’applications avec Nuxt 3": "Compétence 1 : Réaliser un développement d’application\n\nDévelopper une solution pour un client en respectant ses besoins, en appliquant les principes algorithmiques, en veillant à la qualité du code et à sa documentation, et en choisissant les ressources techniques appropriées.\n\nSituations professionnelles :\n\n- Élaborer une application informatique\n- Faire évoluer une application informatique\n- Maintenir en conditions opérationnelles une application informatique\n\nDévelopper des applications informatiques simples :\n\n- AC 1 : Implémenter des conceptions simples\n- AC 2 : Élaborer des conceptions simples\n- AC 3 : Faire des essais et évaluer leurs résultats en regard des spécifications\n- AC 4 : Développer des interfaces utilisateurs\n\nDéveloppement complet d'application :\n\n- AC 1 : Élaborer et implémenter les spécifications fonctionnelles et non fonctionnelles\n- AC 2 : Appliquer des principes d’accessibilité et d’ergonomie\n- AC 3 : Adopter de bonnes pratiques de conception et de programmation\n- AC 4 : Vérifier et valider la qualité de l’application par les tests\n\nAdapter des applications sur divers supports (embarqué, web, mobile, IoT…) :\n\n- AC 1 : Choisir et implémenter les architectures adaptées\n- AC 2 : Faire évoluer une application existante\n- AC 3 : Intégrer des solutions dans un environnement de production",
    "Compétence 2 : Optimisation de la Performance des Applications": "Compétence 2 : Optimiser des applications\n\nProposer des applications informatiques optimisées en fonction de critères spécifiques (temps d'exécution, précision, consommation de ressources…).\n\nFormaliser et modéliser des situations complexes\n\n- Recenser les algorithmes et les structures de données usuels\n- S'appuyer sur des schémas de raisonnement\n- Justifier les choix et valider les résultats\n\nSituations professionnelles :\n\n- Améliorer les performances des programmes dans des contextes contraints\n- Limiter l'impact environnemental d'une application informatique\n- Mettre en place des applications informatiques adaptées et innovantes\n\nComprendre et construire des algorithmes :\n\n- AC 1 : Analyser un problème avec méthode\n- AC 2 : Comparer des algorithmes pour des problèmes classiques\n- AC 3 : Formaliser et mettre en œuvre des outils mathématiques pour l’informatique\n\nSélectionner les algorithmes adéquats pour un problème donné :\n\n- AC 1 : Choisir des structures de données complexes adaptées au problème\n- AC 2 : Utiliser des techniques algorithmiques adaptées pour des problèmes complexes\n- AC 3 : Comprendre les enjeux et moyens de sécurisation des données et du code\n- AC 4 : Évaluer l’impact environnemental et sociétal des solutions proposées\n\nAnalyser et optimiser des applications :\n\n- AC 1 : Anticiper les résultats de diverses métriques (temps d’exécution, occupation mémoire…)\n- AC 2 : Profiler, analyser et justifier le comportement d’un code existant\n- AC 3 : Choisir et utiliser des bibliothèques et méthodes dédiées au domaine d’application"
  }
};

const Portfolio = () => {
  const { language } = useContext(LanguageContext);
  const [currentCompetencyIndex, setCurrentCompetencyIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentCompetencyTitle, setCurrentCompetencyTitle] = useState("");

  const translations = {
    en: {
      title: "Portfolio",
      subtitle:
        "During my second year of my IT Bachelor's program, I completed a 10-week internship at Elisath, from April 8 to June 13. During this internship, I had the opportunity to work on the development of an innovative web application. My tasks included optimizing application performance, creating an intuitive registration page, and developing an error management framework to improve reliability. I also documented the APIs to facilitate future integration, developed a user profile page, and began integrating PayFiP payment functionality. This experience allowed me to strengthen my technical skills, improve my ability to solve complex problems, and gain insight into the dynamic operations of a growing company. Working at Elisath provided me with valuable perspectives on team collaboration and project management in a professional environment.",
      competenciesTitle: "Developed and Applied Skills",
      whatILearnedTitle: "What I Learned",
      competencies: [
        {
          title: "Competency 1: Application Development with Nuxt 3",
          description:
            "During my internship, I was introduced to Nuxt 3 for web application development. I participated in discussions on code structures and had to overcome challenges related to the lack of technical documentation. My work included reviewing Vue.js concepts with the R&D team, integrating Swagger for API documentation, and creating API definitions.",
          examples: [
            {
              title: "Creating and Integrating a Registration Page (AC1, AC4)",
              description:
                "During my internship, I designed and developed a new registration page using Nuxt 3. This project began with an in-depth learning phase on Nuxt 3 and Vue.js. I closely collaborated with the team to understand best practices in code structure and performance optimization. The main goal was to create an intuitive and smooth registration page. I implemented validations and advanced error handling to ensure the application's robustness. To guarantee better maintainability and scalability, I used TypeScript for file modularization. Integrating this page with the backend API required careful evaluation of the connections between the front-end modules and API endpoints. I ensured that each step of the registration process was clear and easy to follow, thus reducing user friction. The results showed a significant increase in registrations, directly linked to the improved user experience.",
            },
            {
              title: "Developing an Error Management Framework (AC1, AC4)",
              description:
                "During my internship, I was also tasked with developing an error management framework to improve the reliability of our systems. I designed this framework by integrating sophisticated early error detection mechanisms. These mechanisms allow for quickly identifying anomalies before they disrupt system operations. Once an error is detected, automated resolution processes are triggered to fix the problem without requiring immediate human intervention. This proactive approach significantly reduced service interruptions, thereby increasing the availability and reliability of our platform. This error management framework was adopted as a model for other projects, demonstrating its robustness and effectiveness. Working on this project allowed me to deepen my knowledge in error management and system optimization, and contribute tangibly to improving the quality of our services.",
            },
            {
              title: "Training and Using Swagger (AC1, AC3)",
              description:
                "During my internship, I was also trained on Swagger for API documentation design. In collaboration with a senior developer, I learned the basics of Swagger and participated in creating the initial API definitions. This process exposed me to Swagger's limitations for backend integration, but it also increased my understanding of interactions between Swagger and our backend infrastructure. I contributed to finalizing the basic definitions in Swagger and resolving various technical issues as a team. This experience allowed me to acquire valuable skills in API documentation and cross-team collaboration.",
            },
            {
              title: "Introduction and Deepening of Nuxt 3 (AC1, AC3)",
              description:
                "An essential part of my internship was dedicated to an in-depth introduction to Nuxt 3, under the guidance of an experienced colleague. I revisited and consolidated my knowledge of Vue.js to apply it effectively in the context of Nuxt 3. I was also involved in reviewing the work on the login module, ensuring that all functionalities aligned with user needs. This preparation allowed me to quickly integrate new features in the subsequent phases of the project. Working on this module helped me develop project management, problem-solving, and team collaboration skills.",
            },
          ],
          images: [
            "https://image.noelshack.com/fichiers/2024/24/2/1718107049-image.jpg",
            "https://image.noelshack.com/fichiers/2024/24/2/1718107121-image-1.png",
            "https://image.noelshack.com/fichiers/2024/24/2/1718107132-image-2.png",
            "https://image.noelshack.com/fichiers/2024/24/2/1718107150-image-3.png",
          ],
        },
        {
          title: "Competency 2: Application Performance Optimization",
          description:
            "Another key part of my internship was optimizing our activity scheduling application using FullCalendar. This project required a complete redesign of the user interface to improve user interaction, system responsiveness, and data management efficiency.",
          examples: [
            {
              title:
                "Redesigning the User Interface of the Scheduling Application (AC1, AC3, AC4)",
              description:
                "I significantly contributed to the redesign of the user interface of our scheduling application. Using FullCalendar, I worked to make the application more intuitive and responsive, which involved implementing features such as drag-and-drop for event management. I collaborated with colleagues to identify user needs and integrate appropriate solutions. Optimizing the application's responsiveness was a key aspect, requiring improvements in backend queries and caching frequently used data. By ensuring that user actions were processed instantly and without noticeable delay, I succeeded in greatly enhancing the user experience. These efforts resulted in a more enjoyable and easier-to-use application, thus increasing user engagement.",
            },
            {
              title: "Developing Advanced Filtering Techniques (AC1, AC3)",
              description:
                "I introduced new filtering techniques to optimize the processing and visualization of complex data sets. I started by analyzing the specific needs of users regarding data search and visualization. Collaborating with end-users, I identified the most relevant filtering criteria and challenges faced with existing filtering systems. I then implemented dynamic and interactive filters, allowing users to modify and adjust filtering criteria in real-time without reloading the page. This approach significantly improved the application's responsiveness and enabled users to find relevant information more quickly. These innovations facilitated navigation and manipulation of large amounts of data, thereby improving decision-making.",
            },
            {
              title:
                "Managing Recurring Events and Backend Integration (AC1, AC3)",
              description:
                "One of the main difficulties encountered during the optimization of our scheduling application was synchronizing recurring events with the backend. I had to deepen my debugging skills to overcome these technical challenges. I worked on integrating calendar filters with the databases, conducting rigorous tests to verify the robustness and accuracy of the new features. This work improved the interaction between calendar filters and various databases, which was crucial for implementing the final version of the application. This experience allowed me to develop skills in data management and system optimization.",
            },
            {
              title:
                "Optimizing the User Interface for Different Devices (AC1, AC3, AC4)",
              description:
                "To ensure a smooth and intuitive user experience on different devices, I worked on making the scheduling application responsive. This involved modifying the display to show daily views on phones and weekly views on computers. I also adjusted the layout and interactive elements for better responsiveness on different screen sizes, ensuring a consistent and enjoyable use regardless of the device used. Working on these optimizations allowed me to acquire valuable skills in responsive design and user experience enhancement.",
            },
            {
              title: "Integrating the Payment API (AC1, AC3)",
              description:
                "Following the finalization of registrations via the scheduling application, I was tasked with integrating the PayFiP payment functionality. This project involved learning and using SOAP and XML technologies, which posed a significant challenge due to my unfamiliarity with these technologies. I focused my efforts on self-training and researching relevant resources to master these technologies effectively. In parallel, I continued to optimize the user interface, taking user feedback into account to improve the overall experience. This mission allowed me to develop skills in payment system integration and the use of complex web technologies.",
            },
          ],
          images: [
            "https://image.noelshack.com/fichiers/2024/24/2/1718107189-image-4.png",
            "https://image.noelshack.com/fichiers/2024/24/2/1718107194-image-5.png",
            "https://image.noelshack.com/fichiers/2024/24/2/1718107196-image-6.png",
          ],
        },
      ],
      whatILearned: {
        interpersonalSkills: {
          title: "Developed Interpersonal Skills",
          items: [
            {
              title: "Communication and Collaboration",
              description:
                "Collaborating with my team on various projects, such as developing the error management framework and optimizing the scheduling application, enhanced my communication and collaboration skills. I learned how to effectively share ideas, provide and receive feedback, and work together to solve complex problems.",
            },
            {
              title: "Project Management",
              description:
                "Managing the development and optimization of different components of the web application required me to develop strong project management skills. I learned to prioritize tasks, coordinate with team members, and ensure timely delivery of high-quality work.",
            },
            {
              title: "Adaptability",
              description:
                "Working with new technologies like Nuxt 3, FullCalendar, and various APIs required me to adapt quickly. I became proficient at learning and applying new tools and methodologies, which demonstrated my flexibility and resilience in a dynamic work environment.",
            },
          ],
        },
        technicalSkills: {
          title: "Developed Technical Skills",
          items: [
            {
              title: "Research and Problem Solving",
              description:
                "Throughout my internship, I encountered various technical challenges that required thorough research and problem-solving skills. Whether it was integrating Swagger for API documentation or optimizing application performance, I enhanced my ability to find and implement effective solutions.",
            },
            {
              title: "System Architecture Understanding",
              description:
                "Working with a complex system architecture involving PostgreSQL, NodeJS helped me understand the intricacies of backend and frontend integration. This knowledge was crucial for tasks like managing recurring events and improving data management efficiency.",
            },
            {
              title: "Proficiency with New Technologies",
              description:
                "My exposure to Nuxt 3, TypeScript, FullCalendar, SOAP, and XML during my internship significantly boosted my proficiency with these technologies. I developed the ability to quickly learn and effectively utilize new tools to meet project requirements.",
            },
            {
              title: "Code Quality and Maintenance",
              description:
                "Developing the registration page and error management framework required me to focus on code quality and maintenance. I learned to write clean, modular, and well-documented code, ensuring long-term maintainability and scalability of the application.",
            },
          ],
        },
      },
    },
    fr: {
      title: "Portfolio",
      subtitle:
        "Lors de ma 2ème année en BUT Informatique, j'ai effectué un stage de 10 semaines chez Elisath, du 8 avril au 13 juin. Pendant ce stage, j'ai eu l'opportunité de travailler sur le développement d'une application web innovante. Mes tâches incluaient l'optimisation des performances de l'application, la création d'une page d'inscription intuitive et le développement d'un cadre de gestion des erreurs pour améliorer la fiabilité. J'ai également documenté les API pour faciliter l'intégration future, développé une page de profil utilisateur et commencé l'intégration du paiement via PayFiP. Cette expérience m'a permis de renforcer mes compétences techniques, d'améliorer ma capacité à résoudre des problèmes complexes et de découvrir le fonctionnement dynamique d'une entreprise en pleine croissance. Travailler chez Elisath m'a offert une perspective précieuse sur la collaboration en équipe et la gestion de projet dans un environnement professionnel.",
      competenciesTitle: "Compétences Développées et Mises en Pratique",
      whatILearnedTitle: "Qu'est-ce que j'en ai tiré ?",
      competencies: [
        {
          title: "Compétence 1 : Développement d’applications avec Nuxt 3",
          description:
            "Durant mon stage, j’ai été initié à Nuxt 3 pour le développement d’applications web. J'ai participé à des discussions sur les structures de code et j'ai dû surmonter les défis liés au manque de documentation technique. Mon travail a inclus la revue des concepts de Vue.js avec l'équipe de R&D, l'intégration de Swagger pour la documentation des API, ainsi que la création de définitions d'API.",
          examples: [
            {
              title: "Création et intégration d'une page d'inscription (AC1, AC4)",
              description:
                "Pendant mon stage, j'ai conçu et développé une nouvelle page d'inscription en utilisant Nuxt 3. Ce projet a débuté par une phase d'apprentissage approfondi sur Nuxt 3 et Vue.js. J'ai collaboré étroitement avec l'équipe pour comprendre les meilleures pratiques en matière de structure de code et d'optimisation des performances. L'objectif principal était de créer une page d'inscription intuitive et fluide. J'ai mis en place des validations et une gestion avancée des exceptions pour assurer la robustesse de l'application. Pour garantir une meilleure maintenabilité et évolutivité, j'ai utilisé TypeScript pour modulariser les fichiers. L'intégration de cette page avec l'API backend a nécessité une évaluation minutieuse des liaisons entre les modules front-end et les endpoints de l'API. J'ai veillé à ce que chaque étape du processus d'inscription soit claire et facile à suivre, réduisant ainsi les frictions pour les utilisateurs. Les résultats obtenus ont montré une augmentation notable des inscriptions, directement liée à l'amélioration de l'expérience utilisateur.",
            },
            {
              title: "Développement d'un cadre de gestion des erreurs (AC1, AC4)",
              description:
                "Une partie significative de mon stage a consisté à développer un cadre de gestion des erreurs pour améliorer la fiabilité de nos systèmes. J'ai conçu ce cadre en intégrant des mécanismes sophistiqués de détection précoce des erreurs. Ces mécanismes permettent de repérer rapidement les anomalies avant qu'elles ne perturbent les opérations du système. Une fois une erreur détectée, des processus de résolution automatisés sont déclenchés pour corriger le problème sans intervention humaine immédiate. Cette approche proactive a considérablement réduit les interruptions de service, augmentant ainsi la disponibilité et la fiabilité de notre plateforme. Ce cadre de gestion des erreurs a été adopté comme modèle pour d'autres projets, démontrant sa robustesse et son efficacité. En travaillant sur ce projet, j'ai pu approfondir mes connaissances en gestion des erreurs et en optimisation des systèmes, ainsi que contribuer de manière tangible à l'amélioration de la qualité de nos services.",
            },
            {
              title: "Formation et utilisation de Swagger (AC1, AC3)",
              description:
                "Pendant mon stage, j'ai également été formé sur Swagger pour la conception de documentation d'API. En collaboration avec un développeur senior, j'ai appris les bases de Swagger et participé à la création des premières définitions d'API. Ce processus m'a confronté aux limitations de Swagger pour l'intégration avec le backend, mais m'a permis d'augmenter ma compréhension des interactions entre Swagger et notre infrastructure backend. J'ai contribué à la finalisation des définitions de base dans Swagger et à la résolution de divers problèmes techniques en équipe. Cette expérience m'a permis d'acquérir des compétences précieuses en documentation d'API et en collaboration inter-équipes.",
            },
            {
              title: "Introduction et approfondissement de Nuxt 3 (AC1, AC3)",
              description:
                "Une partie essentielle de mon stage a été dédiée à une introduction approfondie à Nuxt 3, sous la guidance d'un collègue expérimenté. J'ai revisité et consolidé mes connaissances sur Vue.js pour les appliquer efficacement dans le contexte de Nuxt 3. J'ai également été impliqué dans la révision des travaux sur le module de connexion, m'assurant que toutes les fonctionnalités étaient alignées avec les besoins des utilisateurs. Cette préparation m'a permis d'intégrer rapidement de nouvelles fonctionnalités lors des phases suivantes du projet. En travaillant sur ce module, j'ai développé des compétences en gestion de projet, en résolution de problèmes et en collaboration d'équipe.",
            },
          ],
          images: [
            "https://image.noelshack.com/fichiers/2024/24/2/1718107049-image.jpg",
            "https://image.noelshack.com/fichiers/2024/24/2/1718107121-image-1.png",
            "https://image.noelshack.com/fichiers/2024/24/2/1718107132-image-2.png",
            "https://image.noelshack.com/fichiers/2024/24/2/1718107150-image-3.png",
          ],
        },
        {
          title: "Compétence 2 : Optimisation de la Performance des Applications",
          description:
            "Une autre partie clé de mon stage a été l'optimisation de notre application de planification des activités en utilisant FullCalendar. Ce projet a nécessité une refonte complète de l'interface utilisateur pour améliorer l'interaction utilisateur, la réactivité du système et l'efficacité de la gestion des données.",
          examples: [
            {
              title:
                "Refonte de l'interface utilisateur de l'application de planification (AC1, AC3, AC4)",
              description:
                "J'ai contribué de manière significative à la refonte de l'interface utilisateur de notre application de planification. En utilisant FullCalendar, j'ai travaillé à rendre l'application plus intuitive et réactive, ce qui a impliqué l'implémentation de fonctionnalités telles que le glisser-déposer pour la gestion des événements. J'ai collaboré avec des collègues pour identifier les besoins des utilisateurs et intégrer des solutions adaptées. L'optimisation de la réactivité de l'application a été un aspect clé, nécessitant une amélioration des requêtes backend et la mise en cache des données fréquemment utilisées. En veillant à ce que les actions des utilisateurs soient traitées instantanément et sans retard perceptible, j'ai réussi à améliorer considérablement l'expérience utilisateur. Ces efforts ont abouti à une application plus agréable et plus facile à utiliser, augmentant ainsi l'engagement des utilisateurs.",
            },
            {
              title: "Développement de techniques de filtrage avancées (AC1, AC3)",
              description:
                "J'ai introduit de nouvelles techniques de filtrage pour optimiser le traitement et la visualisation des ensembles de données complexes. J'ai commencé par analyser les besoins spécifiques des utilisateurs en termes de recherche et de visualisation des données. En collaboration avec les utilisateurs finaux, j'ai identifié les critères de filtrage les plus pertinents et les défis rencontrés avec les systèmes de filtrage existants. J'ai ensuite mis en place des filtres dynamiques et interactifs, permettant aux utilisateurs de modifier et d'ajuster les critères de filtrage en temps réel sans recharger la page. Cette approche a considérablement amélioré la réactivité de l'application et a permis aux utilisateurs de trouver les informations pertinentes plus rapidement. Ces innovations ont facilité la navigation et la manipulation de grandes quantités de données, améliorant ainsi la prise de décision.",
            },
            {
              title:
                "Gestion des événements récurrents et intégration avec le backend (AC1, AC3)",
              description:
                "L'une des principales difficultés rencontrées lors de l'optimisation de notre application de planification a été la synchronisation des événements récurrents avec le backend. J'ai dû approfondir mes compétences en débogage pour surmonter ces défis techniques. J'ai travaillé sur l'intégration des filtres du calendrier avec les bases de données, en effectuant des tests rigoureux pour vérifier la robustesse et la précision des nouvelles fonctionnalités. Ce travail a permis d'améliorer l'interaction entre les filtres du calendrier et les différentes bases de données, crucial pour la mise en place de la version finale de l'application. Cette expérience m'a permis de développer des compétences en gestion des données et en optimisation des systèmes.",
            },
            {
              title:
                "Optimisation de l'interface utilisateur pour différents appareils (AC1, AC3, AC4)",
              description:
                "Pour assurer une expérience utilisateur fluide et intuitive sur différents appareils, j'ai travaillé à rendre le planning responsive. Cela impliquait de modifier l'affichage par jours sur téléphone et par semaines sur ordinateur. J'ai également ajusté la disposition et les éléments interactifs pour une meilleure réactivité sur différentes tailles d'écran, garantissant ainsi une utilisation cohérente et agréable quel que soit l'appareil utilisé. En travaillant sur ces optimisations, j'ai acquis des compétences précieuses en design responsive et en amélioration de l'expérience utilisateur.",
            },
            {
              title: "Intégration de l'API de paiement (AC1, AC3)",
              description:
                "Suite à la finalisation des inscriptions via le planning, j'ai été missionné pour intégrer la fonctionnalité de paiement via PayFiP. Ce projet a impliqué l'apprentissage et l'utilisation des technologies SOAP et XML, ce qui représentait un défi majeur en raison de ma non-familiarité avec ces technologies. J'ai concentré mes efforts sur la formation personnelle et la recherche de ressources pertinentes pour maîtriser ces technologies efficacement. En parallèle, j'ai continué à optimiser l'interface utilisateur en tenant compte des retours des utilisateurs pour améliorer l'expérience globale. Cette mission m'a permis de développer des compétences en intégration de systèmes de paiement et en utilisation de technologies web complexes.",
            },
          ],
          images: [
            "https://image.noelshack.com/fichiers/2024/24/2/1718107189-image-4.png",
            "https://image.noelshack.com/fichiers/2024/24/2/1718107194-image-5.png",
            "https://image.noelshack.com/fichiers/2024/24/2/1718107196-image-6.png",
          ],
        },
      ],
      whatILearned: {
        interpersonalSkills: {
          title: "Compétences Interpersonnelles Développées",
          items: [
            {
              title: "Communication et Collaboration",
              description:
                "En collaborant avec mon équipe sur divers projets, tels que le développement du cadre de gestion des erreurs et l'optimisation de l'application de planification, j'ai amélioré mes compétences en communication et en collaboration. J'ai appris à partager efficacement des idées, à donner et à recevoir des retours constructifs, et à travailler ensemble pour résoudre des problèmes complexes.",
            },
            {
              title: "Gestion de Projet",
              description:
                "La gestion du développement et de l'optimisation de différents composants de l'application web m'a obligé à développer de solides compétences en gestion de projet. J'ai appris à prioriser les tâches, à coordonner avec les membres de l'équipe et à garantir la livraison en temps voulu d'un travail de haute qualité.",
            },
            {
              title: "Adaptabilité",
              description:
                "Travailler avec de nouvelles technologies comme Nuxt 3, FullCalendar et diverses API m'a obligé à m'adapter rapidement. J'ai acquis une grande compétence dans l'apprentissage et l'application de nouveaux outils et méthodologies, ce qui a démontré ma flexibilité et ma résilience dans un environnement de travail dynamique.",
            },
          ],
        },
        technicalSkills: {
          title: "Compétences Techniques Développées",
          items: [
            {
              title: "Recherche et Résolution de Problèmes",
              description:
                "Tout au long de mon stage, j'ai rencontré divers défis techniques qui ont nécessité des compétences approfondies en recherche et en résolution de problèmes. Que ce soit pour intégrer Swagger pour la documentation des API ou pour optimiser les performances du site, j'ai amélioré ma capacité à trouver et à mettre en œuvre des solutions efficaces.",
            },
            {
              title: "Compréhension de l'Architecture Système",
              description:
                "Travailler avec une architecture système complexe impliquant PostgreSQL, NodeJS m'a aidé à comprendre les subtilités de l'intégration backend et frontend. Cette connaissance était cruciale pour des tâches telles que la gestion des événements récurrents et l'amélioration de l'efficacité de la gestion des données.",
            },
            {
              title: "Maîtrise des Nouvelles Technologies",
              description:
                "Mon exposition à Nuxt 3, TypeScript, FullCalendar, SOAP et XML pendant mon stage a considérablement amélioré ma maîtrise de ces technologies. J'ai développé la capacité à apprendre rapidement et à utiliser efficacement de nouveaux outils pour répondre aux exigences des projets.",
            },
            {
              title: "Qualité et Maintenance du Code",
              description:
                "Le développement de la page d'inscription et du cadre de gestion des erreurs m'a obligé à me concentrer sur la qualité et la maintenance du code. J'ai appris à écrire un code propre, modulaire et bien documenté, garantissant la maintenabilité et la scalabilité à long terme de l'application.",
            },
          ],
        },
      },
    },
  };

  const currentTranslations = translations[language];

  if (!currentTranslations || !currentTranslations.competencies) {
    return <div>Loading...</div>;
  }

  const handlePrev = () => {
    setCurrentCompetencyIndex((prevIndex) =>
      prevIndex === 0
        ? currentTranslations.competencies.length - 1
        : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentCompetencyIndex((prevIndex) =>
      prevIndex === currentTranslations.competencies.length - 1
        ? 0
        : prevIndex + 1
    );
  };

  const showModal = (competenceTitle) => {
    setCurrentCompetencyTitle(competenceTitle);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <section className="portfolio section" id="portfolio">
      <div className="portfolio__header">
        <h2 className="section__title">{currentTranslations.title}</h2>
      </div>

      <div className="portfolio__container">
        <div className="portfolio__content">
          <div className="portfolio__left">
            <h3 className="competencies__title">
              {currentTranslations.competenciesTitle}
            </h3>
            <img
              src="https://cdn-s-www.estrepublicain.fr/images/5E2CDD74-98B9-4416-B681-C817DAECC42E/FB1200/photo-1454314611.jpg"
              alt="Company Logo"
              className="company-logo"
            />
          </div>
          <div className="portfolio__right">
            <p className="section__subtitle">{currentTranslations.subtitle}</p>
          </div>
        </div>

        <div className="competency__navigation">
          <button onClick={handlePrev} className="navigation__arrow">
            <FaArrowLeft />
          </button>
          <div className="competency__content">
            <h4 className="competency__title">
              {currentTranslations.competencies[currentCompetencyIndex].title}
            </h4>

            <p
              className="competency__description"
              dangerouslySetInnerHTML={{
                __html:
                  currentTranslations.competencies[currentCompetencyIndex]
                    .description,
              }}
            ></p>
            <Collapse accordion>
              {currentTranslations.competencies[
                currentCompetencyIndex
              ].examples.map((example, index) => (
                <Panel
                  header={
                    <span className="example__title">{example.title}</span>
                  }
                  key={index}
                  className="static-bg"
                >
                  <div className="example__description-container">
                    <p
                      className="example__description"
                      dangerouslySetInnerHTML={{ __html: example.description }}
                    ></p>
                    <Button
                      className="ac-button"
                      onClick={() =>
                        showModal(
                          currentTranslations.competencies[
                            currentCompetencyIndex
                          ].title
                        )
                      }
                    >
                      Détail des Compétences
                    </Button>
                  </div>
                </Panel>
              ))}
            </Collapse>

            <div className="portfolio__images">
              {currentTranslations.competencies[
                currentCompetencyIndex
              ].images.map((image, index) => (
                <ModalImage
                  key={index}
                  small={image}
                  large={image}
                  className="portfolio__image"
                />
              ))}
            </div>
          </div>
          <button onClick={handleNext} className="navigation__arrow">
            <FaArrowRight />
          </button>
        </div>

        <div className="what-i-learned section">
          <h3 className="section__title">{currentTranslations.whatILearnedTitle}</h3>
          <div className="what-i-learned__content">
            <div className="interpersonal-skills">
              <h4 className="skills__title">{currentTranslations.whatILearned.interpersonalSkills.title}</h4>
              <ul className="skills__list">
                {currentTranslations.whatILearned.interpersonalSkills.items.map((skill, index) => (
                  <li key={index}>
                    <h5>{skill.title}</h5>
                    <p>{skill.description}</p>
                  </li>
                ))}
              </ul>
            </div>
            <div className="technical-skills">
              <h4 className="skills__title">{currentTranslations.whatILearned.technicalSkills.title}</h4>
              <ul className="skills__list">
                {currentTranslations.whatILearned.technicalSkills.items.map((skill, index) => (
                  <li key={index}>
                    <h5>{skill.title}</h5>
                    <p>{skill.description}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Détail des Compétences"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>{acDescriptions[language][currentCompetencyTitle]}</p>
      </Modal>
    </section>
  );
};

export default Portfolio;
