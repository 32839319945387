// src/components/skills/DataSkills.js

import React, { useContext } from "react";
import { LanguageContext } from '../LanguageContext/LanguageContext';

const DataSkills = () => {
  const { language } = useContext(LanguageContext);

  const translations = {
    en: {
      title: "Data Science Skills",
      skills: [
        { name: "Python", level: "Experienced" },
        { name: "Pandas", level: "Experienced" },
        { name: "NumPy", level: "Experienced" },
        { name: "SciPy", level: "Intermediate" },
        { name: "TensorFlow", level: "Intermediate" },
        { name: "Keras", level: "Intermediate" }
      ]
    },
    fr: {
      title: "Compétences en Science des Données",
      skills: [
        { name: "Python", level: "Expérimenté" },
        { name: "Pandas", level: "Expérimenté" },
        { name: "NumPy", level: "Expérimenté" },
        { name: "SciPy", level: "Intermédiaire" },
        { name: "TensorFlow", level: "Intermédiaire" },
        { name: "Keras", level: "Intermédiaire" }
      ]
    }
  };

  const currentTranslations = translations[language];

  return (
    <div className="skills__content">
      <h3 className="skills__title">{currentTranslations.title}</h3>
      <div className="skills__box">
        <div className="skills__group">
          {currentTranslations.skills.slice(0, 3).map((skill, index) => (
            <div className="skills__data" key={index}>
              <i className={`bx bxl-${skill.name.toLowerCase()} skills-icon`}></i>
              <div>
                <h3 className="skills__name">{skill.name}</h3>
                <span className="skills__level">{skill.level}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="skills__group">
          {currentTranslations.skills.slice(3).map((skill, index) => (
            <div className="skills__data" key={index}>
              <i className={`bx bxl-${skill.name.toLowerCase()} skills-icon`}></i>
              <div>
                <h3 className="skills__name">{skill.name}</h3>
                <span className="skills__level">{skill.level}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DataSkills;
