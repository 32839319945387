import React, { useState, useContext, useEffect } from "react";
import "./header.css";
import LOGO_LIGHT from "../../assets/logo.png";
import LOGO_DARK from "../../assets/logo-light.png";
import { LanguageContext } from "../LanguageContext/LanguageContext";
import { gsap } from "gsap";

const Header = () => {
  useEffect(() => {
    console.log("Header component mounted");
    window.addEventListener("scroll", function () {
      const header = document.querySelector(".header");
      if (this.scrollY >= 80) {
        header.classList.add("scroll-header");
        console.log("Added scroll-header class");
      } else {
        header.classList.remove("scroll-header");
        console.log("Removed scroll-header class");
      }
    });
  }, []);

  const [Toggle, showMenu] = useState(false);
  const [activeNav, setActiveNav] = useState("#home");
  const { language, toggleLanguage, darkMode, toggleDarkMode } =
    useContext(LanguageContext);

  useEffect(() => {
    console.log(`Language: ${language}, Dark mode: ${darkMode}`);
  }, [language, darkMode]);

  const handleToggleDarkMode = () => {
    toggleDarkMode();
    if (darkMode) {
      gsap.to("#sun", { opacity: 1, duration: 0.5 });
      gsap.to("#moon", { opacity: 0, duration: 0.5 });
    } else {
      gsap.to("#sun", { opacity: 0, duration: 0.5 });
      gsap.to("#moon", { opacity: 1, duration: 0.5 });
    }
  };

  return (
    <header className="header">
      <nav className="nav container">
        <a href="#home" className="nav__logo">
          <img
            src={darkMode ? LOGO_DARK : LOGO_LIGHT}
            alt="robin's logo"
            className="logo"
          />
        </a>

        <div className={Toggle ? "nav__menu show-menu" : "nav__menu"}>
          <ul className="nav__list">
            <li className="nav__item">
              <a
                href="#about"
                onClick={() => setActiveNav("#about")}
                className={
                  activeNav === "#about" ? "nav__link active-link" : "nav__link"
                }
              >
                <i className="uil uil-user nav__icon"></i>
                {language === "fr" ? "À propos" : "About"}
              </a>
            </li>

            <li className="nav__item">
              <a
                href="#skills"
                onClick={() => setActiveNav("#skills")}
                className={
                  activeNav === "#skills"
                    ? "nav__link active-link"
                    : "nav__link"
                }
              >
                <i className="uil uil-brain nav__icon"></i>
                {language === "fr" ? "Compétences" : "Skills"}
              </a>
            </li>

            <li className="nav__item">
              <a
                href="#experience"
                onClick={() => setActiveNav("#experience")}
                className={
                  activeNav === "#experience"
                    ? "nav__link active-link"
                    : "nav__link"
                }
              >
                <i className="uil uil-bag nav__icon"></i>
                {language === "fr" ? "Expérience" : "Experience"}
              </a>
            </li>

            <li className="nav__item">
              <a
                href="#portfolio"
                onClick={() => setActiveNav("#portfolio")}
                className={
                  activeNav === "#portfolio"
                    ? "nav__link active-link"
                    : "nav__link"
                }
              >
                <i className="uil uil-scenery nav__icon"></i>
                {language === "fr" ? "Portfolio" : "Portfolio"}
              </a>
            </li>

            <li className="nav__item">
              <a
                href="#projects"
                onClick={() => setActiveNav("#projects")}
                className={
                  activeNav === "#projects"
                    ? "nav__link active-link"
                    : "nav__link"
                }
              >
                <i className="uil uil-scenery nav__icon"></i>
                {language === "fr" ? "Projets" : "Projects"}
              </a>
            </li>

            <li className="nav__item">
              <a
                href="#contact"
                onClick={() => setActiveNav("#contact")}
                className={
                  activeNav === "#contact"
                    ? "nav__link active-link"
                    : "nav__link"
                }
              >
                <i className="uil uil-message nav__icon"></i>
                {language === "fr" ? "Contact" : "Contact"}
              </a>
            </li>
          </ul>

          <i
            className="uil uil-times nav__close"
            onClick={() => showMenu(!Toggle)}
          ></i>
        </div>

        <div className="nav__toggle" onClick={() => showMenu(!Toggle)}>
          <i className="uil uil-bars"></i>
        </div>

        <div className="nav__actions">
          <select
            value={language}
            onChange={(e) => toggleLanguage(e.target.value)}
            className="language__select"
          >
            <option value="fr">FR</option>
            <option value="en">EN</option>
          </select>
          <div className="darkModeToggle" onClick={handleToggleDarkMode}>
            <span id="sun" role="img" aria-label="sun" className="emoji">
              ☀️
            </span>
            <span id="moon" role="img" aria-label="moon" className="emoji">
              🌙
            </span>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
