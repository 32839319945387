import Balonu from "../../assets/Balonu.jpg";
import hibitude from "../../assets/hibitudee.gif";
import BudgetManager from "../../assets/BudgetManager.jpg";

export const projectsData = [
  {
    "id": 1,
    "image": Balonu,
    "title": "Balonu",
    "description_fr": `
      <p>Balonu est une <strong>plateforme web</strong> dédiée à la présentation et à la gestion des manifestations de montgolfières dans la magnifique région de la Cappadoce.</p>
      <br>
      <h3>À propos du projet</h3>
      <p>Ce projet vise à offrir une expérience utilisateur complète pour les personnes souhaitant participer à des manifestations de montgolfières en Cappadoce. Il couvre à la fois le backend, qui gère la logique métier et la base de données, et le frontend, qui propose une interface utilisateur intuitive et réactive.</p>
      <br>
      <h3>Fonctionnalités</h3>
      <ul>
        <li><strong>Calendrier des Événements :</strong> Consultez le calendrier pour les prochaines manifestations.</li>
        <li><strong>Réservation en Ligne :</strong> Réservez votre place pour une aventure inoubliable en montgolfière.</li>
        <li><strong>Informations Touristiques :</strong> Découvrez la Cappadoce, son histoire et ses sites touristiques.</li>
        <li><strong>Galerie :</strong> Parcourez les photos des précédentes manifestations.</li>
        <li><strong>Contact et Support :</strong> Contactez-nous pour toute question ou assistance.</li>
      </ul>
      <br>
      <h3>Technologies Utilisées</h3>
      <ul>
        <li><strong>Backend :</strong> Node.js / Express.js</li>
        <li><strong>Frontend :</strong> React.js avec une approche responsive design</li>
        <li><strong>Base de données :</strong> MongoDB</li>
        <li><strong>Authentification :</strong> JWT pour une connexion sécurisée</li>
        <li><strong>APIs :</strong> Intégration de Google Maps pour les localisations des manifestations.</li>
      </ul>`,
    "description_en": `
      <p>Balonu is a <strong>web platform</strong> dedicated to showcasing and managing hot air balloon events in the beautiful region of Cappadocia.</p>
      <br>
      <h3>About the Project</h3>
      <p>This project aims to provide a comprehensive user experience for those wishing to participate in hot air balloon events in Cappadocia. It covers both the backend, which handles business logic and the database, and the frontend, which offers an intuitive and responsive user interface.</p>
      <br>
      <h3>Features</h3>
      <ul>
        <li><strong>Event Calendar :</strong> View the calendar for upcoming events.</li>
        <li><strong>Online Booking :</strong> Reserve your spot for an unforgettable hot air balloon adventure.</li>
        <li><strong>Tourist Information :</strong> Learn about Cappadocia, its history, and tourist sites.</li>
        <li><strong>Gallery :</strong> Browse photos from previous events.</li>
        <li><strong>Contact and Support :</strong> Reach out to us for any questions or assistance.</li>
      </ul>
      <br>
      <h3>Technologies Used</h3>
      <ul>
        <li><strong>Backend :</strong> Node.js / Express.js</li>
        <li><strong>Frontend :</strong> React.js with a responsive design approach</li>
        <li><strong>Database :</strong> MongoDB</li>
        <li><strong>Authentication :</strong> JWT for secure login</li>
        <li><strong>APIs :</strong> Integration of Google Maps for event locations.</li>
      </ul>`,
    "demo": "https://balonu.vercel.app/",
    "code": "https://github.com/nexiath/balonu_"
  },  
  {
    "id": 2,
    "image": hibitude,
    "title": "Hibitude",
    "description_fr": `
      <p>Bienvenue sur Hibitude, l'application d'habitude innovante qui allie simplicité et motivation quotidienne. Hibitude est née de l'envie de créer une application facile à utiliser, qui aide les utilisateurs à instaurer et maintenir des habitudes positives dans leur vie quotidienne. Le nom "Hibitude" fusionne le salut "Hi" avec le mot "habitude", reflétant l'esprit convivial et encourageant de notre application.</p>
      <br>
      <h3>Fonctionnalités</h3>
      <ul>
        <li><strong>Ajout d'habitudes :</strong> Créez et personnalisez vos habitudes avec des noms descriptifs pour suivre facilement vos progrès.</li>
        <li><strong>Enregistrement de l'utilisateur :</strong> Enregistrez votre nom pour personnaliser votre expérience Hibitude.</li>
        <li><strong>Phrases de motivation :</strong> Recevez des messages de motivation quotidiens pour rester inspiré et concentré sur vos objectifs.</li>
        <li><strong>Statistiques :</strong> Consultez vos statistiques quotidiennes et hebdomadaires pour suivre l'évolution de vos habitudes et ajuster vos objectifs en conséquence.</li>
        <li><strong>Trophées :</strong> Gagnez des trophées en fonction du nombre d'habitudes réalisées, ajoutant une couche ludique et gratifiante à votre parcours.</li>
      </ul>
      <br>
      <h3>Technologies Utilisées</h3>
      <ul>
        <li><strong>Développement :</strong> Swift, utilisant les dernières fonctionnalités iOS pour une expérience utilisateur fluide et réactive.</li>
        <li><strong>Gestion des données :</strong> CoreData pour un suivi efficace et rapide des habitudes.</li>
      </ul>
      <br>
      <h3>Contribution</h3>
      <p>Ce projet est le fruit d'un travail universitaire, mais nous sommes ouverts aux contributions de la communauté. Si vous avez des suggestions d'amélioration ou souhaitez contribuer au code, n'hésitez pas à soumettre une pull request ou à ouvrir un issue.</p>
      <p>Hibitude est plus qu'une application; c'est votre compagnon de motivation quotidien. Commencez votre voyage vers de meilleures habitudes dès aujourd'hui !</p>`,
    "description_en": `
      <p>Welcome to Hibitude, the innovative habit app that combines simplicity and daily motivation. Hibitude was born from the desire to create an easy-to-use app that helps users establish and maintain positive habits in their daily lives. The name "Hibitude" merges the greeting "Hi" with the word "habit," reflecting the friendly and encouraging spirit of our app.</p>
      <br>
      <h3>Features</h3>
      <ul>
        <li><strong>Habit Creation:</strong> Create and customize your habits with descriptive names to easily track your progress.</li>
        <li><strong>User Registration:</strong> Register your name to personalize your Hibitude experience.</li>
        <li><strong>Motivational Quotes:</strong> Receive daily motivational messages to stay inspired and focused on your goals.</li>
        <li><strong>Statistics:</strong> View your daily and weekly statistics to track your habits' progress and adjust your goals accordingly.</li>
        <li><strong>Trophies:</strong> Earn trophies based on the number of habits completed, adding a fun and rewarding layer to your journey.</li>
      </ul>
      <br>
      <h3>Technologies Used</h3>
      <ul>
        <li><strong>Development:</strong> Swift, leveraging the latest iOS features for a smooth and responsive user experience.</li>
        <li><strong>Data Management:</strong> CoreData for efficient and fast habit tracking.</li>
      </ul>
      <br>
      <h3>Contribution</h3>
      <p>This project is the result of university work, but we welcome community contributions. If you have improvement suggestions or want to contribute to the code, feel free to submit a pull request or open an issue.</p>
      <p>Hibitude is more than an app; it's your daily motivation companion. Start your journey to better habits today!</p>`,
    "code": "https://github.com/nexiath/Hibitude"
  },  
  {
    "id": 3,
    "image": BudgetManager,
    "title": "BudgetManager",
    "description_fr": `
      <p>BudgetManager est une application de gestion de budget développée avec C++ et le framework Qt. Elle permet aux utilisateurs d'enregistrer leur budget, d'ajouter des dépenses, de visualiser l'évolution de leur portefeuille financier, et d'obtenir un aperçu visuel des catégories de dépenses via un diagramme circulaire. Les utilisateurs peuvent également sauvegarder les informations de leur portefeuille sous forme d'image ou de fichier texte pour une gestion et un suivi facilités.</p>
      <br>
      <h3>Fonctionnalités</h3>
      <ul>
        <li><strong>Enregistrement du budget :</strong> Commencez par définir votre budget pour suivre vos finances.</li>
        <li><strong>Ajout de dépenses :</strong> Saisissez vos dépenses en les catégorisant pour mieux comprendre vos habitudes de consommation.</li>
        <li><strong>Visualisation de l'évolution :</strong> Observez comment votre solde change au fil du temps.</li>
        <li><strong>Aperçu des dépenses :</strong> Utilisez le diagramme circulaire pour une représentation visuelle des catégories de vos dépenses.</li>
        <li><strong>Sauvegarde et exportation :</strong> Enregistrez votre portefeuille et vos dépenses en image ou en texte pour une consultation ultérieure.</li>
      </ul>
      <br>
      <h3>Technologies Utilisées</h3>
      <ul>
        <li><strong>Développement :</strong> C++ avec le framework Qt.</li>
        <li><strong>Visualisation :</strong> Qt Charts pour les diagrammes circulaires.</li>
      </ul>
      <br>
      <h3>Contribution</h3>
      <p>Ce projet est ouvert aux contributions. Si vous avez des suggestions d'amélioration ou souhaitez contribuer au code, n'hésitez pas à soumettre une pull request ou à ouvrir un issue.</p>
      <p>BudgetManager est votre compagnon de gestion financière quotidien. Prenez le contrôle de vos finances dès aujourd'hui !</p>`,
    "description_en": `
      <p>BudgetManager is a budget management application developed with C++ and the Qt framework. It allows users to record their budget, add expenses, visualize the evolution of their financial portfolio, and get a visual overview of expense categories via a pie chart. Users can also save their portfolio information as an image or text file for easy management and tracking.</p>
      <br>
      <h3>Features</h3>
      <ul>
        <li><strong>Budget Recording:</strong> Start by setting your budget to track your finances.</li>
        <li><strong>Expense Addition:</strong> Enter your expenses with categories to better understand your spending habits.</li>
        <li><strong>Evolution Visualization:</strong> Observe how your balance changes over time.</li>
        <li><strong>Expense Overview:</strong> Use the pie chart for a visual representation of your expense categories.</li>
        <li><strong>Save and Export:</strong> Save your portfolio and expenses as an image or text for later reference.</li>
      </ul>
      <br>
      <h3>Technologies Used</h3>
      <ul>
        <li><strong>Development:</strong> C++ with the Qt framework.</li>
        <li><strong>Visualization:</strong> Qt Charts for pie charts.</li>
      </ul>
      <br>
      <h3>Contribution</h3>
      <p>This project is open to contributions. If you have improvement suggestions or want to contribute to the code, feel free to submit a pull request or open an issue.</p>
      <p>BudgetManager is your daily financial management companion. Take control of your finances today!</p>`,
    "demo": "",
    "code": "https://github.com/nexiath/BudgetManager"
  }
  
];
