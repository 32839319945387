import React, { useContext } from 'react';
import { SiPostman } from "react-icons/si";
import { GrMysql } from "react-icons/gr";
import { DiGithubBadge } from "react-icons/di";
import { FaLinux } from "react-icons/fa";
import { AiFillGitlab } from "react-icons/ai";
import { LanguageContext } from '../LanguageContext/LanguageContext';

const ToolsFrameworks = () => {
  const { language } = useContext(LanguageContext);

  const translations = {
    en: {
      title: "Tools & Frameworks",
      skills: [
        { name: "VS Code", level: "Experienced", icon: <i className="bx bxl-visual-studio skills-icon"></i> },
        { name: "GitHub", level: "Experienced", icon: <DiGithubBadge className="skills-icon" /> },
        { name: "MySQL", level: "Experienced", icon: <GrMysql className="skills-icon" /> },
        { name: "Postman", level: "Experienced", icon: <SiPostman className="skills-icon" /> },
        { name: "GNU/Linux", level: "Intermediate", icon: <FaLinux className="skills-icon" /> },
        { name: "GitLab", level: "Intermediate", icon: <AiFillGitlab className="skills-icon" /> }
      ]
    },
    fr: {
      title: "Outils & Frameworks",
      skills: [
        { name: "VS Code", level: "Expérimenté", icon: <i className="bx bxl-visual-studio skills-icon"></i> },
        { name: "GitHub", level: "Expérimenté", icon: <DiGithubBadge className="skills-icon" /> },
        { name: "MySQL", level: "Expérimenté", icon: <GrMysql className="skills-icon" /> },
        { name: "Postman", level: "Expérimenté", icon: <SiPostman className="skills-icon" /> },
        { name: "GNU/Linux", level: "Intermédiaire", icon: <FaLinux className="skills-icon" /> },
        { name: "GitLab", level: "Intermédiaire", icon: <AiFillGitlab className="skills-icon" /> }
      ]
    }
  };

  const currentTranslations = translations[language];

  return (
    <div className="skills__content">
      <h3 className="skills__title">{currentTranslations.title}</h3>
      <div className="skills__box">
        <div className="skills__group">
          {currentTranslations.skills.slice(0, 3).map((skill, index) => (
            <div className="skills__data" key={index}>
              {skill.icon}
              <div>
                <h3 className="skills__name">{skill.name}</h3>
                <span className="skills__level">{skill.level}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="skills__group">
          {currentTranslations.skills.slice(3).map((skill, index) => (
            <div className="skills__data" key={index}>
              {skill.icon}
              <div>
                <h3 className="skills__name">{skill.name}</h3>
                <span className="skills__level">{skill.level}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ToolsFrameworks;
